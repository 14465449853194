import styled from "styled-components";
import { Layout, Menu, Grid } from "antd";
import { HomeOutlined, MailOutlined } from "@ant-design/icons";
import * as ROUTES from "constants/routes";
import Link from "components/shared/CustomLink";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const Sidebar = ({ pathname, collapsed, setCollapsed }) => {
  const screens = useBreakpoint();

  const currentMenuKey = (key) => {
    if (pathname.search(key) >= 0) {
      return pathname;
    } else {
      return key;
    }
  };

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth={0}>
      <StyledContainer>
        <div>
          <StyledLogo />

          <Menu
            theme="dark"
            mode="inline"
            defaultOpenKeys={["job", "staff", "client"]}
            selectedKeys={[pathname]}
          >
            <Menu.Item
              key={ROUTES.DASHBOARD}
              onClick={() => screens.xs && setCollapsed(true)}
            >
              <Link href={ROUTES.DASHBOARD}>
                <span>
                  <HomeOutlined />
                  <span>Dashboard</span>
                </span>
              </Link>
            </Menu.Item>

            <Menu.SubMenu key="job" icon={<MailOutlined />} title="Job">
              <Menu.Item
                key={ROUTES.JOBS}
                onClick={() => screens.xs && setCollapsed(true)}
              >
                <Link href={ROUTES.JOBS}>All jobs</Link>
              </Menu.Item>
              <Menu.Item
                key={ROUTES.JOBS_ADD}
                onClick={() => screens.xs && setCollapsed(true)}
              >
                <Link href={ROUTES.JOBS_ADD}>Create</Link>
              </Menu.Item>
              <Menu.ItemGroup title="Todo">
                <Menu.Item
                  key={currentMenuKey("/todo")}
                  onClick={() => screens.xs && setCollapsed(true)}
                >
                  <Link href={ROUTES.TODO_TEMPLATE}>Template</Link>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Dateline">
                <Menu.Item
                  key={currentMenuKey("/jobs/dateline")}
                  onClick={() => screens.xs && setCollapsed(true)}
                >
                  <Link href={ROUTES.DATELINE_TEMPLATE}>Template</Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="client"
              title={
                <span>
                  <MailOutlined />
                  <span>Client</span>
                </span>
              }
            >
              <Menu.ItemGroup key="a1" title="Detail">
                <Menu.Item
                  key={ROUTES.CLIENTS}
                  onClick={() => screens.xs && setCollapsed(true)}
                >
                  <Link href={ROUTES.CLIENTS}>All clients</Link>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.Item
                key={ROUTES.CLIENTS_ADD}
                onClick={() => screens.xs && setCollapsed(true)}
              >
                <Link href={ROUTES.CLIENTS_ADD}>Add client</Link>
              </Menu.Item>
            </Menu.SubMenu>

            {/* Staff Menu */}
            <Menu.SubMenu
              key="staff"
              title={
                <span>
                  <MailOutlined />
                  <span>Staff</span>
                </span>
              }
            >
              <Menu.Item
                key={ROUTES.STAFF}
                onClick={() => screens.xs && setCollapsed(true)}
              >
                <Link href={ROUTES.STAFF}>All staff</Link>
              </Menu.Item>
              <Menu.Item
                key={ROUTES.STAFF_ADD}
                onClick={() => screens.xs && setCollapsed(true)}
              >
                <Link href={ROUTES.STAFF_ADD}>Add staff</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </div>
      </StyledContainer>
    </Sider>
  );
};

const StyledLogo = styled.div`
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;
export default Sidebar;
