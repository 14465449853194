import * as ROUTES from "../constants/routes";
export const breadcrumbName = {
  [ROUTES.JOBS]: "Jobs",
  [ROUTES.JOBS_DETAIL]: "Detail",
  [ROUTES.JOBS_ADD]: "Add",
  [ROUTES.CLIENTS]: "Clients",
  [ROUTES.CLIENTS_DETAIL]: "Detail",
  [ROUTES.CLIENTS_UPDATE]: "Update",
  [ROUTES.CLIENTS_ADD]: "Add",
  [ROUTES.STAFF]: "Staff",
  [ROUTES.STAFF_DETAIL]: "Detail",
  [ROUTES.STAFF_ADD]: "Add",
  [ROUTES.STAFF_UPDATE_ROLE]: "Role",
  [ROUTES.TODO]: "Todo",
  [ROUTES.TODO_TEMPLATE]: "Template",
  [ROUTES.TODO_TEMPLATE_DETAIL]: "Detail",
  [ROUTES.TODO_TEMPLATE_ADD]: "Add",
  [ROUTES.TODO_TEMPLATE_UPDATE]: "Update",
  [ROUTES.DATELINE_TEMPLATE]: "Template",
  [ROUTES.TODO_TEMPLATE_DETAIL]: "Detail",
  [ROUTES.DATELINE]: "Dateline",
  [ROUTES.DATELINE_TEMPLATE_ADD]: "Add",
  [ROUTES.DATELINE_TEMPLATE_DETAIL]: "Details",
  [ROUTES.DATELINE_TEMPLATE_UPDATE]: "Update",
};
//comment
