import React, { useContext } from "react";
import styled from "styled-components";
import { queryCache } from "react-query";
import { Menu, Avatar, Button, Dropdown, Row, Layout, Spin } from "antd";
import {
  UserOutlined,
  VideoCameraOutlined,
  LoadingOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

import { LoginStateContext } from "context/Login/LoginState";
import useUser from "lib/useUser";

const { Header } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Navbar({ setCollapsed, collapsed }) {
  const { user: userState, logoutUser } = useContext(LoginStateContext);
  const { user, status } = useUser();

  const handleLogout = () => {
    localStorage.removeItem("token");
    logoutUser();
    queryCache.removeQueries("current-user");
  };

  const menu = (
    <Menu style={{ padding: "10px 15px" }}>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.alipay.com/"
        >
          1st menu item
        </a>
      </Menu.Item>
      <Menu.Item key="2" icon={<VideoCameraOutlined />} onClick={handleLogout}>
        <Button type="link" onClick={handleLogout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledHeader>
      <Header>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          }
        )}
        {user ? (
          <>
            <div>
              <Avatar icon={<UserOutlined />} />
              <Dropdown overlay={menu} className="user-dropdown">
                <Button type="link">Hi, {user.name}</Button>
              </Dropdown>
            </div>
          </>
        ) : (
          <>
            <h1>{status}</h1>
            <Spin indicator={antIcon} />
          </>
        )}
      </Header>
    </StyledHeader>
  );
}

const StyledHeader = styled.div`
  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }

  .user-dropdown {
    height: 100%;
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 12px;
    cursor: pointer;
    transition: color 0.3s;
    color: ${(props) => props.theme.colors.primary};
  }

  .trigger:hover {
    color: ${(props) => props.theme.colors.green[400]};
  }
`;

export default Navbar;
