import Link from "next/link";
import { Breadcrumb as AntdBreadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

import { breadcrumbName } from "lib/breadcrumbName";

const Breadcrumb = ({ router }) => {
  const pathSnippets = router.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <AntdBreadcrumb.Item key={url}>
        <Link href={url}>
          <a>{breadcrumbName[url]}</a>
        </Link>
      </AntdBreadcrumb.Item>
    );
  });
  const homeItem =
    extraBreadcrumbItems.length > 0 ? (
      <HomeOutlined />
    ) : (
      <>
        <HomeOutlined style={{ marginRight: "10px" }} />
        Home
      </>
    );
  const breadcrumbItems = [
    <AntdBreadcrumb.Item key="home">
      <Link href="/">{homeItem}</Link>
    </AntdBreadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return <AntdBreadcrumb>{breadcrumbItems}</AntdBreadcrumb>;
};

export default Breadcrumb;
