import styled from "styled-components";
import React, { useState, useEffect, useContext } from "react";
import { Layout, Divider, message, Grid } from "antd";
import { useRouter } from "next/router";
import { NotificationStateContext } from "context/UI/Notification/NotificationState";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Breadcrumb from "./Breadcrumb";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const DefaultLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const router = useRouter();
  const { state: notificationState } = useContext(NotificationStateContext);

  const screens = useBreakpoint();

  useEffect(() => {
    if (screens.xs || (!screens.lg && !screens.xl)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [screens]);

  useEffect(() => {
    if (notificationState.successMessage) {
      message.success(notificationState.successMessage);
    }
  }, [notificationState.successMessage]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar
        pathname={router.pathname}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <Layout className="site-layout">
        <Navbar setCollapsed={setCollapsed} collapsed={collapsed} />
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 280,
          }}
        >
          <Breadcrumb router={router} />
          <StyledDivider />
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

const StyledDivider = styled(Divider)`
  border-color: ${(props) => props.theme.colors.grey[300]};
`;

export const getLayout = (page) => <DefaultLayout>{page}</DefaultLayout>;

export default DefaultLayout;
